function directUploadInitialize(event) {
    const {target, detail} = event
    const {id, file} = detail
    target.insertAdjacentHTML("beforebegin", `
            <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
              <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
              <span class="direct-upload__filename"></span>
            </div>
          `)
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
}

function directUploadStart(event) {
    const {id} = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
}

function directUploadProgress(event) {
    const {id, progress} = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
}


function directUploadError(event) {
    event.preventDefault()
    const {id, error} = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
}


function directUploadEnd(event) {
    const {id} = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")
}

let directUpload = () => {
    removeEventListener("direct-upload:initialize", directUploadInitialize)
    addEventListener("direct-upload:initialize", directUploadInitialize)

    removeEventListener("direct-upload:start", directUploadStart)
    addEventListener("direct-upload:start", directUploadStart)

    removeEventListener("direct-upload:progress", directUploadProgress)
    addEventListener("direct-upload:progress", directUploadProgress)

    removeEventListener("direct-upload:error", directUploadError)
    addEventListener("direct-upload:error", directUploadError)

    removeEventListener("direct-upload:end", directUploadEnd)
    addEventListener("direct-upload:end", directUploadEnd)
}

export default directUpload;