import {underscoredToCamelCase} from "../shared/utilities";

let loadViews = function () {
    if ($('body').data("controller") + $('body').data("controller-action") != null) {
        let controller = underscoredToCamelCase($('body').data("controller"));
        let action = underscoredToCamelCase($('body').data("controller-action"));
        window.namespace = underscoredToCamelCase($('body').data("namespace"));
        console.log(`Looking for: ./${controller}/${action}.js`);
        import(`./${controller}/${action}.js`).then(actionScript => {
            console.log(`Loaded: ${controller}/${action}`);
            actionScript.default();
        }).catch((reason) => null);
    }
};


export default loadViews;