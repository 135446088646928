const datePicker = () => {
    $('body').on('click', '[data-toggle="datepicker"]', function () {
        if (!$(this).data('daterangepicker')) {
            $(this).daterangepicker({
                singleDatePicker: true,
                showDropdowns: true,
                minDate: $(this).data('min-date') ? $(this).data('min-date') : new Date(),
                opens: $(this).data('direction') ? $(this).data('direction') : "right",
                locale: {
                    format: 'DD-MMM-YYYY'
                }
            }).focus();
        }
    });
    $('body').on('click', '[data-toggle="past-datepicker"]', function () {
        if (!$(this).data('daterangepicker')) {
            $(this).daterangepicker({
                singleDatePicker: true,
                showDropdowns: true,
                opens: $(this).data('direction') ? $(this).data('direction') : "right",
                maxDate: $(this).data('max-date') ? $(this).data('max-date') : new Date(),
                locale: {
                    format: 'DD-MMM-YYYY'
                }
            }).focus();
        }
    });
};

export default datePicker